<template>
    <div class="mt-1" style="position: relative; height: 50%; width: 100%">
        <!-- <div v-if="schedules.length == 0 && !edit && isLoading == false"
      style="height: 100%; position: relative; width: 100%">
      <NoDataTemplate class="pt-12" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
        icon="mdi-calendar-remove" text="Keine Zeitpläne vorhanden" />
    </div> -->
        <div v-if="isLoading&&!edit" style="height: 100%; position: relative; width: 100%">
            <LoadingProjectData class="pt-12" style="
                  position: relative;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                " text="" />
        </div>
        <div v-else>
            <div class="pb-5" style="min-height: 100%; height: 100%; position: absolute; width: 100%">
                <ScheduleTemplate :key="updateTrigger" :save="saveTimetableDialog" v-on:updated="updateTimeblockDataEvent"
                    :isEditing="edit" :schedule="schedule" :projectId="projectId">
                    <template v-slot:bottom>
                        <div class="ml-3" style="font-size: 14px">
                            <!-- <div v-if="!edit">
                                <v-row v-show="schedules.length>0" class="ma-0" align="center">
                                    {{$t('tools.project.arming.description')}}:
                                    <b class="ml-1" v-if="schedule.name!=null">{{
                                        schedule.name
                                    }}</b><span v-else>-</span>
                                
                                </v-row>

                                <div v-show="schedules.length==0" class="font-weight-bold">
                                    {{$t('tools.project.arming.noSchedule')}}
                                </div>
                            </div> -->
                            <div v-if="edit">
                                <v-row class="ma-0" align="center">
                                    <v-icon color="primary" size="16" class="mr-1">mdi-pencil</v-icon><span
                                        class="primary--text font-weight-bold" style="font-size: 14px">{{
                                            $t('tools.project.arming.editMode')}}</span>
                                </v-row>
                            </div>
                        </div>
                        <v-spacer />
                        <div v-if="!edit" class="mr-2" v-show="schedules.length>0" style="font-size: 14px">
                            {{$t('tools.project.arming.lastRefresh')}}:
                            <b class="ml-1">{{
                                $helper.formatDate(schedule.createdAt, true, false)
                            }}</b>
                        </div>
                        <span v-if="author!=null&&!edit" class="mr-3 grey--text" style="font-size: 14px">({{author
                        }})</span>
                        <span v-if="edit" style="font-size: 14px" class="grey--text mr-3">{{
                            $t('tools.project.arming.editHint')
                        }}</span>
                    </template>
                </ScheduleTemplate>
            </div>

            <v-dialog :persistent="saveDialogLoading" v-model="saveTimetableDialog" width="450">
                <SaveScheduleDialog :key="saveTimetableDialog" :updated="updatedScheduleData"
                    v-on:saved="savedScheduleEvent" :projectId="projectId" v-on:loading="loadingSaveDialogEvent"
                    v-on:close="saveTimetableDialog=false" :devices="devices" :data="schedule" />
            </v-dialog>
        </div>
    </div>
</template>

<script>
// import NoDataTemplate from '@components/page/NoDataTemplate.vue';
import LoadingProjectData from '@components/loading/LoadingProjectData.vue';
import ScheduleTemplate from '@/components/tools/project/schedule/ScheduleTemplate.vue';
// import IconButton from '@components/button/IconButton.vue';
// import TextButton from '@components/button/TextButton.vue';
import SaveScheduleDialog from '@/components/tools/project/schedule/dialog/SaveSchedule.vue';
// import IconButton from '@components/button/IconButton.vue';
// import TextButton from '@components/button/TextButton.vue';

export default {
    name: 'Schedule',
    props: {
        delay: {
            type: Number,
            default: 0,
        },
        cancel: {
            type: Boolean,
            required: true,
        },
        projectId: {
            type: String,
            required: true,
        },
        allowCapture: {
            type: Boolean,
            default: true,
        },
        create: {
            type: Boolean,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        save: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        schedule: {
            handler() {
                if (this.edit) this.$emit('changes');
            },
            deep: true,
        },
        edit() {
            if (this.edit == true) {
                this.editScheduleCopy = JSON.stringify(this.schedule);
            }
        },
        cancel() {
            this.$emit('finishEdit');
            this.$nextTick(() => {
                this.schedule = null;
                this.schedule = JSON.parse(this.editScheduleCopy);
                this.editScheduleCopy = null;
                this.updateTrigger = !this.updateTrigger;
            });
        },
        saveTimetableDialog() {
            if (this.saveTimetableDialog == false) this.$emit('cancelSave');
        },

        save() {
            this.saveTimetableDialog = this.save;
        },
    },

    data() {
        return {
            deviceNotSynced: false,
            editScheduleCopy: null,
            loadingSync: null,
            loadingAll: false,
            isSyncing: false,
            updatedScheduleData: false,
            saveTimetableDialog: false,
            selectedDevices: [],
            devices: [],

            saveDialogLoading: false,
            isEditing: null,
            isFailed: true,
            isLoading: true,
            schedules: [],
            schedule: null,
            updateTrigger: true,
        };
    },
    components: {
        // TextButton,
        ScheduleTemplate,
        SaveScheduleDialog,
        LoadingProjectData,
        // NoDataTemplate,
        // IconButton
    },

    created() {
        this.getProjectSchedules();
    },

    computed: {
        project() {
            return this.$store.getters.project.general;
        },
        author() {
            if (
                this.$store.getters.projectUsers == null ||
                this.schedule.createdBy == null
            )
                return;

            var userIndex = this.$store.getters.projectUsers.findIndex(
                (user) => user.userId == this.schedule.createdBy,
            );
            if (userIndex == -1) return 'AlinoCam';
            else
                return (
                    this.$store.getters.projectUsers[userIndex].meta.name || 'AlinoCam'
                );
        },
        errorDevices() {
            if (!this.devices) return;
            return this.devices.filter((device) => {
                if (
                    (device.schedule != null && device.schedule.status != 1) ||
                    this.loadingAll == true
                )
                    return device;
            });
        },
    },

    methods: {
        async savedScheduleEvent() {
            await this.getProjectSchedules(false);
            this.saveTimetableDialog = false;

            this.$emit('save');
        },
        loadingSaveDialogEvent(status) {
            this.saveDialogLoading = status;
            if (status == false) this.$emit('finishEdit');
        },

        updateTimeblockDataEvent(updatedTimeblock) {
            const TIMEBLOCK_ARMING = 1;
            const TIMEBLOCK_CAPTURE = 2;

            const scheduleModes = {
                noSetting: 0,
                fullArming: 1,
                fullCapture: 2,
                setTimeRange: 3
            }

            const timeModes = {
                arming: 0,
                capture: 1,
                none: 2,
            }

            const hasCapture = this.allowCapture;

            if (!this.schedule.timeblocks) this.schedule.timeblocks = [];

            var timeblockEntry = [];

            switch (updatedTimeblock.type) {
                case scheduleModes.noSetting:
                    timeblockEntry = [];
                    break;
                case scheduleModes.fullArming:
                    timeblockEntry.push({
                        startTime: 0,
                        endTime: 1440,
                        type: TIMEBLOCK_ARMING,
                    });
                    break;
                case scheduleModes.fullCapture:
                    timeblockEntry.push({
                        startTime: 0,
                        endTime: 1440,
                        type: TIMEBLOCK_CAPTURE,
                    });
                    break;
                case scheduleModes.setTimeRange:
                    if (
                        (this.project.allowArming == true &&
                            this.project.allowRecordings == false) ||
                        (this.project.allowArming == false &&
                            this.project.allowRecordings == true)
                    ) {
                        if (
                            updatedTimeblock.timeRange.in === timeModes.arming &&
                            updatedTimeblock.timeRange.out === timeModes.none
                        ) {
                            timeblockEntry.push({
                                startTime: updatedTimeblock.time.start,
                                endTime: updatedTimeblock.time.end,
                                type: TIMEBLOCK_ARMING,
                            });
                        }
                        if (
                            updatedTimeblock.timeRange.in === timeModes.none &&
                            updatedTimeblock.timeRange.out === timeModes.arming
                        ) {
                            timeblockEntry.push({
                                startTime: 0,
                                endTime: updatedTimeblock.time.start,
                                type: TIMEBLOCK_ARMING,
                            });
                            timeblockEntry.push({
                                startTime: updatedTimeblock.time.end,
                                endTime: 1440,
                                type: TIMEBLOCK_ARMING,
                            });
                        }
                        if (
                            updatedTimeblock.timeRange.in === timeModes.capture &&
                            updatedTimeblock.timeRange.out === timeModes.none
                        ) {
                            timeblockEntry.push({
                                startTime: updatedTimeblock.time.start,
                                endTime: updatedTimeblock.time.end,
                                type:
                                    this.project.allowArming == true
                                        ? TIMEBLOCK_ARMING
                                        : TIMEBLOCK_CAPTURE,
                            });
                        }
                        if (
                            updatedTimeblock.timeRange.in === timeModes.none &&
                            updatedTimeblock.timeRange.out === timeModes.capture
                        ) {
                            timeblockEntry.push({
                                startTime: 0,
                                endTime: updatedTimeblock.time.start,
                                type:
                                    this.project.allowArming == true
                                        ? TIMEBLOCK_ARMING
                                        : TIMEBLOCK_CAPTURE,
                            });
                            timeblockEntry.push({
                                startTime: updatedTimeblock.time.end,
                                endTime: 1440,
                                type:
                                    this.project.allowArming == true
                                        ? TIMEBLOCK_ARMING
                                        : TIMEBLOCK_CAPTURE,
                            });
                        }
                    }

                    if (
                        (this.project.allowArming == true &&
                            this.project.allowRecordings == true)
                    ) {
                        if (
                            updatedTimeblock.time.start > 0 &&
                            updatedTimeblock.timeRange.out != 2
                        ) {
                            timeblockEntry.push({
                                startTime: 0,
                                endTime: updatedTimeblock.time.start,
                                type:
                                    updatedTimeblock.timeRange.out == 1
                                        ? TIMEBLOCK_CAPTURE
                                        : TIMEBLOCK_ARMING,
                            });
                        }
                        if (hasCapture && updatedTimeblock.timeRange.in != 2) {
                            timeblockEntry.push({
                                startTime: updatedTimeblock.time.start,
                                endTime: updatedTimeblock.time.end,
                                type:
                                    updatedTimeblock.timeRange.in == 1
                                        ? TIMEBLOCK_CAPTURE
                                        : TIMEBLOCK_ARMING,
                            });
                        }
                        if (
                            updatedTimeblock.time.end < 1440 &&
                            updatedTimeblock.timeRange.out != 2
                        ) {
                            timeblockEntry.push({
                                startTime: updatedTimeblock.time.end,
                                endTime: 1440,
                                type:
                                    updatedTimeblock.timeRange.out == 1
                                        ? TIMEBLOCK_CAPTURE
                                        : TIMEBLOCK_ARMING,
                            });
                        }
                    }
                    break;

                default:
                    console.log("Fehler!")
                //   this.$toast.error(
                //     this.$toast.error('tools.project.arming.toasts.errorSaving'),
                //   );
            }

            var scheduleTimeblocks = this.schedule.timeblocks.filter(
                (block) => !updatedTimeblock.days.includes(block.dayOfWeek),
            );

            if (timeblockEntry.length > 0) {
                updatedTimeblock.days.forEach((weekday) =>
                    timeblockEntry.forEach((entry) =>
                        scheduleTimeblocks.push({ ...entry, dayOfWeek: weekday }),
                    ),
                );
            }

            this.schedule.timeblocks = scheduleTimeblocks;
        },
        checkSyncStatus() {
            var isUpdating = false;
            var noSync = false;
            this.devices.forEach((device) => {
                if (device.schedule != null && device.schedule.status == 2)
                    isUpdating = true;
                if (
                    device.schedule != null &&
                    device.schedule.status != 2 &&
                    device.schedule.status != 1
                )
                    noSync = true;
            });
            this.$emit('status', isUpdating ? 2 : noSync ? 0 : 1);

            this.deviceNotSynced = noSync;
            this.isSyncing = isUpdating;
            if (isUpdating) {
                setTimeout(() => {
                    this.getProjectSchedules(false);
                }, 2500);
            }
        },

        async getProjectSchedules(loading = true) {
            if (this.edit && loading == true) return;
            if (loading) this.isLoading = true;

            setTimeout(async () => {
                var scheduleReqResp = await Promise.all([
                    this.$api.project.schedules(this.projectId, false),
                    this.$api.project.devices(this.projectId),
                ]);

                if (
                    !scheduleReqResp[0].success ||
                    scheduleReqResp[1] == null ||
                    scheduleReqResp[0].data == null
                ) {
                    this.isFailed = true;
                    return this.$toast.error('Fehler beim Laden...');
                }
                if (
                    scheduleReqResp[0].data.schedules != null &&
                    scheduleReqResp[0].data.schedules.length > 0
                ) {
                    if (scheduleReqResp[0].data.schedules.length > 1) {
                        await Promise.all(
                            scheduleReqResp[0].data.schedules
                                .slice(0, -1)
                                .map((schedule) =>
                                    this.$api.project.deleteSchedule(
                                        this.$route.params.projectId,
                                        schedule.id,
                                    ),
                                ),
                        );
                        scheduleReqResp[0].data.schedules = [
                            scheduleReqResp[0].data.schedules[
                            scheduleReqResp[0].data.schedules.length - 1
                            ],
                        ];
                    }

                    scheduleReqResp[1].devices.forEach((device, index) => {
                        scheduleReqResp[0].data.schedules.forEach((schedule) => {
                            if (
                                schedule.scheduleEntries == null ||
                                schedule.scheduleEntries.length == 0
                            )
                                return;

                            schedule.scheduleEntries.forEach((entry) => {
                                if (entry.deviceId == device.id)
                                    scheduleReqResp[1].devices[index].schedule = {
                                        id: schedule.id,
                                        name: schedule.name,
                                        createdAt: entry.createdAt,
                                        status: entry.status,
                                    };
                            });
                        });
                    });
                }

                this.schedules =
                    scheduleReqResp[0].data.schedules == null
                        ? []
                        : scheduleReqResp[0].data.schedules;
                if (
                    scheduleReqResp[0].data.total > 0 &&
                    scheduleReqResp[0].data.schedules.reduce((a, b) =>
                        !a.archived ? a : b,
                    ) != null
                ) {
                    this.schedule =
                        scheduleReqResp[0].data.schedules[
                        scheduleReqResp[0].data.schedules.length - 1
                        ];
                } else {
                    this.schedule = { name: '', isNewSchedule: true, timeblocks: [] };
                    this.$emit('noSchedule');
                }

                this.devices = scheduleReqResp[1].devices;

                this.checkSyncStatus();

                if (loading) this.isLoading = false;

                this.updatedScheduleData = !this.updatedScheduleData;
            }, this.delay);
        },
    },
};
</script>

<style scoped>
@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    to {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(3630deg);
        transform: rotate(360deg);
    }

    to {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}
</style>
