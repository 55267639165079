<template>
    <DialogTemplate icon="mdi-content-save" :closeable="!isLoading"
        :title="$t('tools.project.arming.dialog.saveSchedule.title')" v-on:close="$emit('close')">
        <template v-slot:content>
            <div class="pb-1">
                <TextInput class="mt-0" :prefix="$t('tools.project.arming.dialog.saveSchedule.input.name')"
                    v-on:change="updatedScheduleNameEvent" :placeholder="$t('tools.project.arming.dialog.saveSchedule.input.namePlaceholder')
                        " :value="scheduleName" />
                <!-- <v-textarea
        class="mt-2"
      placeholder="Beschreibung"
      auto-grow
        v-model="scheduleDescr"
      filled rounded style="border-radius: 10px!important" hide-details
      ></v-textarea> -->
                <div class="ml-0 mt-4 mb-2" style="font-size: 14px; user-select: none">
                    <v-row class="ma-0" align="start">
                        <v-icon class="mr-2 mt-0" style="margin-top: 2px" size="18" color="primary">mdi-information</v-icon>

                        <div style="max-width: calc(100% - 30px)">
                            {{
                                $t('tools.project.arming.dialog.saveSchedule.saveText')
                            }}
                        </div>
                    </v-row>
                </div>
            </div>
        </template>

        <template v-slot:actions>
            <v-row class="ma-0" align="center">
                <v-spacer />

                <TextButton dense :loading="isLoading" :disabled="scheduleName==null||scheduleName.length==0"
                    v-on:click="saveSchedule" buttonColor="primary" :text="$t('tools.project.arming.dialog.saveSchedule.buttons.saveSync')
                            " />
            </v-row>
        </template>
    </DialogTemplate>
</template>

<script>
import DialogTemplate from '@components/dialog/DialogTemplate.vue';
import TextButton from '@components/button/TextButton.vue';
import TextInput from '@components/text/TextInput.vue';

export default {
    name: 'SaveSchedule',
    components: {
        TextButton,
        TextInput,
        DialogTemplate,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
        devices: {
            type: Array,
            required: true,
        },
        projectId: {
            type: String,
            required: true,
        },
        updated: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            isLoading: false,
            scheduleName: '',
            scheduleDescr: this.data.description,
        };
    },

    watch: {
        updated() {
            this.$emit('close');
            this.setLoading(false);
        },
    },

    methods: {
        updatedScheduleNameEvent(nameString) {
            this.scheduleName = nameString;
        },
        setLoading(status) {
            this.isLoading = status;
            this.$emit('loading', status);
        },

        async addScheduleDevice(scheduleId, device) {
            var synced = false;

            var resp = await this.$api.project.addScheduleDevice(
                this.projectId,
                device.id,
                scheduleId,
            );
            if (resp.success == true) synced = true;

            return { status: synced, device: device };
        },

        async saveSchedule() {
            this.setLoading(true);
            var updateReq = null;
            if (this.data.isNewSchedule == true) {
                updateReq = await this.$api.project.createProjectSchedule({
                    projectId: this.projectId,
                    timeblocks: this.data.timeblocks,
                    name: this.scheduleName,
                    description: this.scheduleDescr,
                });
            } else {
                updateReq = await this.$api.project.updateProjectSchedule(
                    this.projectId,
                    this.data.id,
                    {
                        timeblocks: this.data.timeblocks,
                        name: this.scheduleName,
                        description: this.scheduleDescr,
                    },
                );
            }

            console.log(updateReq);
            if (
                updateReq.success == true &&
                updateReq.data != null &&
                updateReq.data.id != null &&
                this.data.isNewSchedule == true
            ) {
                var devicePromiseArr = [];
                this.devices.forEach((device) =>
                    devicePromiseArr.push(
                        this.addScheduleDevice(updateReq.data.id, device),
                    ),
                );

                await Promise.all(devicePromiseArr);
            }

            this.$emit('saved');
            this.setLoading(false);
            location.reload();

            this.$nextTick(() => {
                this.setLoading(false);
            });
        },
    },
};
</script>
