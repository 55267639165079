<template>
    <ProjectPageTemplate style="position: relative; height: 100%; width: 100%;" :key="loadingScheduleUpdate"
        :isSettings="true" :tabs="true" :error="isFailed" :title="$t(`${$route.meta.i18n}.title`)" :searchable="false"
        :loading="false">
        <!-- legend -->
        <template v-slot:header-additional>
            <v-row class="ma-0 ml-8 mt-2" align="center">
                <div v-show="project.allowArming==true" class=" " style="
                                    height: 18px;
                                    width: 25px;
                                    background-color: #707070;
                                    border-radius: 5px;
                                  " />
                <span v-show="project.allowArming==true" style="font-size: 14px"
                    class="ml-1 mr-5 font-weight-bold grey--text">{{$t('tools.project.arming.legend.arming')}}</span>

                <div v-show="project.allowRecordings==true" style="
                                    height: 18px;
                                    width: 25px;
                                    background-color: #d0d0d0;
                                    border: 1px solid #a0a0a0;
                                    border-radius: 5px;
                                  " />
                <span v-show="project.allowRecordings==true" style="font-size: 14px"
                    class="ml-1 font-weight-bold grey--text">{{$t('tools.project.arming.legend.capture')}}</span>

                <div class="stripes ml-5" style="
                                    height: 18px;
                                    width: 25px;
                                    background-color: #f8f8f8;
                                    border: 1px solid #a0a0a0;
                                    border-radius: 5px;
                                  " />
                <span style="font-size: 14px" class="ml-1 font-weight-bold grey--text">{{
                    $t('tools.project.arming.legend.none')}}</span>
            </v-row>
        </template>

        <template v-slot:header-action>
            <v-row v-if="canWrite" class="ma-0" align="center" v-show="!isLoading&&canWrite">
                <div v-if="!editSchedule">
                    <IconButton :disabled="!canWrite" icon="mdi-pencil" tooltipPosition="l"
                        :tooltip="$t('tools.project.arming.editSchedule')" class="mr-1"
                        v-on:click="editSchedule=!editSchedule" style="border-radius: 50%; border: 1px solid #c0c0c0" />
                </div>
                <div v-else>
                    <v-row class="ma-0" align="center">
                        <!-- <TextButton class="mr-2"  v-on:click="cancelEditSchedule = !cancelEditSchedule" text="Abbrechen" buttonColor="rgba(220, 220, 220, 1)"/> -->

                        <IconButton icon="mdi-close" tooltipPosition="l" :tooltip="$t('tools.project.arming.cancelEdit')"
                            class="mr-2" v-on:click="cancelEditSchedule=!cancelEditSchedule"
                            style="border-radius: 50%; border: 1px solid #c0c0c0" />
                        <TextButton v-on:click="saveSchedule=true" icon="mdi-content-save"
                            :text="$t('helper.buttons.submit')" buttonColor="primary" />
                    </v-row>
                </div>
            </v-row>
            <span v-else class="grey--text font-weight-normal mr-3"
                style="font-size: 14px; letter-spacing: 1.4px">READ-ONLY</span>
        </template>
        <template v-slot:content>
            <div class="mt-2 ml-3 mr-3" style="position: relative; height: 100%; overflow: hidden">
                <Schedule v-on:save="savedSchedule" style="height: 100%" v-on:noSchedule="noScheduleEvent"
                    v-on:status="scheduleStatusEvent" v-on:finishEdit="editSchedule=false"
                    v-on:cancelSave="saveSchedule=false" :cancel="cancelEditSchedule" :save="saveSchedule"
                    :edit="editSchedule" :allowCapture="project.allowRecordings==true" v-on:created="createdScheduleEvent"
                    :create="createScheduleTrigger" :projectId="$route.params.projectId" />
            </div>
        </template>
    </ProjectPageTemplate>
</template>

<script>
import ProjectPageTemplate from '@/components/tools/project/ProjectPageTemplate.vue';
import Schedule from '@/components/tools/project/schedule/Schedule.vue';
import TextButton from '@components/button/TextButton.vue';
import IconButton from '@components/button/IconButton.vue';

export default {
    name: 'ProjectViewSchedule',
    components: {
        TextButton,
        ProjectPageTemplate,
        // ScheduleDeviceSync,
        Schedule,
        IconButton,
    },




    data() {
        return {
            loadingScheduleUpdate: false,
            cancelEditSchedule: false,
            saveSchedule: false,
            editSchedule: false,
            isLoading: true,
            isFailed: false,
            createScheduleTrigger: false,
            noSchedule: false,
        };
    },

    computed: {

        canWrite() {
            return this.$auth.canWrite();
        },
        devices() {
            return this.$store.getters.project.devices;
        },

        project() {
            return this.$store.getters.project.general;
        },
    },

    methods: {
        savedSchedule() {
            this.loadingScheduleUpdate = !this.loadingScheduleUpdate;
            this.cancelEditSchedule = false;
            this.saveSchedule = false;
            this.editSchedule = false;
            this.isLoading = true;
            this.isFailed = false;
            this.createScheduleTrigger = false;
            this.noSchedule = false;
        },
        noScheduleEvent() {
            this.isLoading = false;
            this.noSchedule = true;
        },
        scheduleStatusEvent() {
            this.isLoading = false;
        },
        createdScheduleEvent() {
            this.createScheduleTrigger = false;
        },
    },
};
</script>

<style scoped>
.stripes {
    background: repeating-linear-gradient(-45deg,
            transparent,
            transparent 6px,
            rgba(0, 0, 0, 0.05) 6px,
            rgba(0, 0, 0, 0.05) 12px);
}

.stripes-arming {
    background: repeating-linear-gradient(-45deg,
            transparent,
            transparent 4px,
            var(--v-primary-base) 4px,
            var(--v-primary-base) 8px);
}
</style>
