<template>
  <div
    style="
      position: relative;
      height: 100%;
      width: 100%;
      border: 1px solid #d0d0d0;
      border-radius: 10px;
    "
  >
    <div class="timetable">
      <div
        v-for="dayIdentifier in 7"
        class="timetable-days"
        :key="`day-id-handle-${dayIdentifier}`"
      >
        <v-hover v-slot="{hover}" :disabled="!isEditing">
          <div
            style="position: relative; height: calc(100% - 75px); width: 100%"
            v-on:click="editTimeblockDialog(dayIdentifier)"
          >
            <div
              class="weekday-header font-weight-bold"
              :class="hover ? 'primary--text' : ''"
              :style="
                dayIdentifier == 7
                  ? 'border-left: 1px solid rgba(220, 220, 220, 1); '
                  : dayIdentifier > 1
                  ? 'border-left: 1px solid rgba(220, 220, 220, 1)'
                  : ''
              "
            >
              <span>{{ $t('helper.weekdays')[dayIdentifier - 1].long }}</span>
            </div>
            <div
              :style="
                isEditing
                  ? 'cursor: pointer'
                  : 'pointer-events: none!important;'
              "
              v-on:click="editTimeblockDialog(dayIdentifier)"
            >
              <div
                class="timetable-content"
                :style="
                  isEditing &&
                  editWeekdayDialog.show == true &&
                  editWeekdayDialog.data.weekday == dayIdentifier
                    ? `border: 2px solid var(--v-primary-base); border-radius: 10px; overflow: hidden;`
                    : ''
                "
              >
                <div
                  v-show="currDatetime.dayOfWeek == dayIdentifier && !isEditing"
                  style="
                    position: absolute;
                    width: calc(100% - 8px);
                    height: 3px;
                    border-radius: 1px;
                    z-index: 2;
                    background-color: white;
                    margin: 4px;
                  "
                  :style="`top: calc((calc(100%/24)) * ${
                    currDatetime.min / 60
                  } )`"
                ></div>
                <div
                  v-for="time in 23"
                  :key="`time-divider-${time}`"
                  class="timetable-divider"
                  style="height: calc((100%) / 24)"
                />
                <div
                  id=""
                  class="time-element stripes"
                  style="
                    background-color: rgba(0, 0, 0, 0.8);
                    height: 100%;
                    top: 0px;
                    background-color: #f0f0f0;
                  "
                ></div>
                <div
                  class="time-element stripes-arming"
                  style="background-color: #707070"
                  :style="displayTimeblock(timeBlockType.arming, 0, schedule, dayIdentifier)"
                >
                  <div
                    v-show="
                      showTimeRangeInformation &&
                      (!isEditing ||
                        editWeekdayDialog.show == false ||
                        (editWeekdayDialog.show == true &&
                          editWeekdayDialog.data.weekday == dayIdentifier))
                    "
                    class="black--text timeblock-time-info"
                  >
                    {{ displayTimeRange(timeBlockType.arming, 0, schedule, dayIdentifier) }}
                  </div>
                </div>

                <div
                  class="grey lighten-1 time-element"
                  :style="displayTimeblock(timeBlockType.capture, 0, schedule, dayIdentifier)"
                >
                  <div
                    v-show="
                      showTimeRangeInformation &&
                      (!isEditing ||
                        editWeekdayDialog.show == false ||
                        (editWeekdayDialog.show == true &&
                          editWeekdayDialog.data.weekday == dayIdentifier))
                    "
                    class="black--text timeblock-time-info"
                  >
                    {{ displayTimeRange(timeBlockType.capture, 0, schedule, dayIdentifier) }}
                  </div>
                </div>

                <div
                  class="grey lighten-1 time-element"
                  :style="displayTimeblock(timeBlockType.capture, 1, schedule, dayIdentifier)"
                >
                  <div
                    v-show="
                      showTimeRangeInformation &&
                      (!isEditing ||
                        editWeekdayDialog.show == false ||
                        (editWeekdayDialog.show == true &&
                          editWeekdayDialog.data.weekday == dayIdentifier))
                    "
                    class="black--text timeblock-time-info"
                  >
                    {{ displayTimeRange(timeBlockType.capture, 1, schedule, dayIdentifier) }} 
                  </div>
                </div>
                <div
                  class="time-element stripes-arming"
                  style="background-color: #707070"
                  :style="displayTimeblock(timeBlockType.arming, 1, schedule, dayIdentifier)"
                >
                  <div
                    v-show="
                      showTimeRangeInformation &&
                      (!isEditing ||
                        editWeekdayDialog.show == false ||
                        (editWeekdayDialog.show == true &&
                          editWeekdayDialog.data.weekday == dayIdentifier))
                    "
                    class="black--text timeblock-time-info"
                  >
                    {{ displayTimeRange(timeBlockType.arming, 1, schedule, dayIdentifier) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-hover>
      </div>

      <v-row
        style="position: absolute; bottom: 0px; height: 35px; width: 100%"
        class="ma-0"
        align="center"
      >
        <slot name="bottom" />
      </v-row>
    </div>

    <div
      :style="editDialogPosition"
      style="
        position: absolute;
        z-index: 100;
        top: 50px;
        border-radius: 10px;
        width: 350px;
      "
      class="elevation-1"
      v-if="editWeekdayDialog.data != null && isEditing == true"
      v-show="editWeekdayDialog.show"
      width="650"
    >
      <EditTimeblockDialog
        :show="editWeekdayDialog.show && isEditing"
        v-on:close="editWeekdayDialog.show = false"
        v-on:updated="updatedTimeblockDataEvent"
        :weekday="editWeekdayDialog.data.weekday"
        :data="editWeekdayDialog.data.timeblocks"
      />
    </div>
  </div>
</template>

<script>
import EditTimeblockDialog from '@/components/tools/project/schedule/dialog/EditTimeblockDialog.vue';
export default {
  name: 'ScheduleTemplate',
  props: {
    save: {
      type: Boolean,
    },
    isSyncing: {
      type: Boolean,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
    },

    projectId: {
      type: String,
      required: false,
    },
    allowCapture: {
      type: Boolean,
      default: true,
    },
    create: {
      type: Boolean,
    },
  },

  components: {
    EditTimeblockDialog,
  },

  data() {
    return {
      currDatetime: {
        dayOfWeek: 0,
        min: 0,
      },
      editWeekdayDialog: {
        show: false,
        data: null,
      },
      showTimeRangeInformation: true,

      changes: false,
      isFailed: true,
      isLoading: true,

      timeBlockType: {
        arming: 1,
        capture: 2,
      }
    };
  },

  computed: {
    editDialogPosition() {
      if (this.editWeekdayDialog.data.weekday <= 3) {
        return `left: calc(100%/7 * ${this.editWeekdayDialog.data.weekday} + 5px)`;
      } else {
        return `right:  calc(100%/7 * (8 - ${this.editWeekdayDialog.data.weekday}) + 5px)`;
      }
    },
  },

  watch: {
    save() {
      if (this.save == true) {
        this.editWeekdayDialog.show = false;
      }
    },
    isEditing() {
      this.editWeekdayDialog.show = false;
    },
  },

  created() {
    var d = new Date(),
      e = new Date(d);
    var min = (e - d.setHours(0, 0, 0, 0)) / 60000;
    var weekday = d.getDay();
    if (weekday == 0) weekday = 7;
    this.currDatetime = {min: min, dayOfWeek: weekday};
    // console.log(weekday, min)
  },

  methods: {
    updatedTimeblockDataEvent(updatedTimeblock) {
      return this.$emit('updated', updatedTimeblock);
    },

    editTimeblockDialog(weekday) {
      if (this.isEditing == false) return;

      var weekdayTimeblocks = [];
      if (this.schedule.timeblocks != null) {
        weekdayTimeblocks = this.schedule.timeblocks
          .filter((block) => block.dayOfWeek == weekday)
          .sort((blockA, blockB) => blockA.startTime - blockB.startTime);
      }

      this.editWeekdayDialog.data = {
        weekday: weekday,
        timeblocks: weekdayTimeblocks,
      };
      this.editWeekdayDialog.show = true;
    },

    parseFloatTime(time) {
      if (time == 1440) time = 0;
      return `${`0${parseInt(time / 60)}`.slice(-2)}:${`0${parseInt(
        60 * (time / 60 - parseInt(time / 60)),
      )}`.slice(-2)}`;
    },
    parseStringTime(time) {
      return +time.split(':')[0] * 60 + +time.split(':')[1];
    },

    displayTimeRange(type, id, scheduleObj, dayId) {
      const timeblockObj = this.matchedSchedule(scheduleObj, dayId, type);
      if (
        timeblockObj == null ||
        timeblockObj.length == 0 ||
        timeblockObj[id] == null
      )
        return;
      else
        return `${this.parseFloatTime(
          timeblockObj[id].startTime,
        )} - ${this.parseFloatTime(timeblockObj[id].endTime)}`;
    },

    displayTimeblock(type, id, scheduleObj, dayId) {
      const timeblockObj = this.matchedSchedule(scheduleObj, dayId, type);
      if (!timeblockObj) return 'display: none';

      if (timeblockObj.length - 1 < id) return 'display: none;';

      const startTime =
        timeblockObj[id].startTime < 0 ? 0 : timeblockObj[id].startTime;
      const endTime =
        timeblockObj[id].endTime > 1440 ? 1440 : timeblockObj[id].endTime;

      if (startTime == null || endTime == null || startTime > 1440)
        return 'display: none;';

      return `top: calc((calc(100%/24)) * ${
        startTime / 60
      } ); height: calc((100%/24) * ${(endTime - startTime) / 60}); `;
    },

    matchedSchedule(scheduleObj, dayId, type) {
      if (!scheduleObj || !scheduleObj.timeblocks) return;

      var matchedEntries = scheduleObj.timeblocks.filter(
        (block) => block.dayOfWeek == dayId && block.type == type,
      );
      if (matchedEntries == null || matchedEntries.length == 0) return;

      return matchedEntries.sort(
        (blockA, blockB) => blockA.startTime - blockB.startTime,
      );
    },
  },
};
</script>

<style>
.timetable {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
  overflow: hidden;
}

.timetable-days {
  width: calc(100% / 7);
  background-color: transparent;
  min-height: 100%;
  height: 100%;
  float: left;
  position: relative;
}

.timetable-content {
  height: 100%;
  width: calc(100% - 2px);
  border-bottom: 1px solid rgba(220, 220, 220, 1);
  background-color: white;
  position: absolute;
  border: 2px solid transparent;
  margin: 1px;
}

.timetable-content:hover {
  height: 100%;
  width: calc(100% - 2px);
  border-bottom: 1px solid rgba(220, 220, 220, 1);
  background-color: white;
  position: absolute;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #a0a0a0;
  margin: 1px;
}

.weekday-header {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border-bottom: 1px solid rgba(220, 220, 220, 1);
  text-align: center;
  vertical-align: middle;
  line-height: 38px;
  position: relative;
}

.timetable-divider {
  position: relative;
}

.time-element {
  background-color: transparent;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.timeblock-time-info {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  vertical-align: middle;
  width: fit-content;
  padding: 2px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  opacity: 1;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.day-selector {
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  border: 1px solid var(--v-primary-base);
  text-align: center;
  vertical-align: middle;
  line-height: 25px;
  cursor: pointer;
}

#stripes {
  height: 200px;
  width: 2000px;
  background-color: red;
}

.stripes {
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 12px,
    rgba(150, 150, 150, 0.05) 12px,
    rgba(150, 150, 150, 0.05) 24px
  );
}
</style>
