<template>
  <DialogTemplate noHeader title="" v-on:close="$emit('close')">
    <!-- <template v-slot:title-button>
    <span> </span>
    <span class="font-weight-bold ml-1" style="letter-spacing: 1.6px; font-size: 19px;">{{`${weekdays[(weekday - 1)].full.toUpperCase()}`}}</span>
  </template> -->

    <template v-slot:content>
      <div class="grey--text ml-1 mt-3" style="font-size: 14px">
        {{ $t('tools.project.arming.dialog.editWeekday.applyWeekdays') }}
      </div>

      <!-- ------------------------------------------------------------------ -->
      <!-- TITLE OF DIALOG -->
      <!-- ------------------------------------------------------------------ -->
      <v-row class="ma-0 ml-1 mt-1" align="center">
        <div
          v-on:click="addSelectedDayEvent(i)"
          style="user-select: none"
          v-for="i in 7"
          :key="`day-selector-dialog-${i}`"
          :style="
            i === weekday || timeblockConfig.days.includes(i)
              ? 'background-color: var(--v-primary-base); color: white; pointer-events: none'
              : 'color: var(--v-primary-base)'
          "
          class="day-selector"
        >
          <span style="font-size: 13px">
            {{ $t('helper.weekdays')[i - 1].short }}</span
          >
        </div>
      </v-row>

      <!-- ---------------------------------------------------------------- -->
      <!-- SCHEDULE MODE OF DIALOG -->
      <!-- ---------------------------------------------------------------- -->
      <div
        class="mt-1"
        style="background-color: #f0f0f0; border: 1px solid #d0d0d0; border-radius: 10px;"
      >
        <div style="font-size: 14px" class="grey--text ml-3 mt-2">
          {{ $t('tools.project.arming.dialog.editWeekday.scheduleMode') }}
        </div>

        <v-radio-group
          v-model="timeblockConfig.type"
          column
          dense
          hide-details
          class="pb-2 pt-2 pl-2"
          style="padding-top: 0px; margin-top: 0px"
        >
          <v-radio class="pr-1" hide-details :ripple="false" :value="scheduleModes.noSetting"
            ><template v-slot:label>
              <span
                :class="
                  timeblockConfig.type === scheduleModes.noSetting
                    ? 'primary--text font-weight-bold'
                    : ''
                "
                style="font-size: 16px"
                >{{
                  $t('tools.project.arming.dialog.editWeekday.mode.none')
                }}</span
              >
            </template></v-radio
          >

          <v-radio
            v-show="project.allowArming"
            class="pr-1"
            hide-details
            :ripple="false"
            :value="scheduleModes.fullArming"
            ><template v-slot:label>
              <span
                :class="
                  timeblockConfig.type === scheduleModes.fullArming
                    ? 'primary--text font-weight-bold'
                    : ''
                "
                style="font-size: 16px"
                >{{
                  $t('tools.project.arming.dialog.editWeekday.mode.fullArming')
                }}</span
              >
            </template></v-radio
          >

          <v-radio
            v-show="project.allowRecordings"
            class="pr-1"
            hide-details
            :ripple="false"
            :value="scheduleModes.fullCapture"
            ><template v-slot:label>
              <span
                :class="
                  timeblockConfig.type === scheduleModes.fullCapture
                    ? 'primary--text font-weight-bold'
                    : ''
                "
                style="font-size: 16px"
                >{{
                  $t(
                    'tools.project.arming.dialog.editWeekday.mode.fullDocumentation',
                  )
                }}</span
              >
            </template></v-radio
          >

          <v-radio hide-details :ripple="false" label="Arbeitszeiten" :value="scheduleModes.setTimeRange"
            ><template v-slot:label>
              <span
                :class="
                  timeblockConfig.type === scheduleModes.setTimeRange
                    ? 'primary--text font-weight-bold '
                    : ''
                "
                style="font-size: 16px"
                >{{
                  $t(
                    'tools.project.arming.dialog.editWeekday.mode.setTimerange',
                  )
                }}</span
              >
            </template></v-radio
          >
        </v-radio-group>
      </div>

      <!-- ---------------------------------------------------------------- -->
      <!-- SET TIME PERIOD OF DIALOG -->
      <!-- ---------------------------------------------------------------- -->
      <div
        v-show="timeblockConfig.type === 3"
        style="border: 1px solid #d0d0d0; background-color: rgba(220, 220, 220, 0.4); border-radius: 10px;"
        class="mt-2"
      >
        <div class="grey--text ml-1 mt-3 ml-3" style="font-size: 14px">
          {{ $t('tools.project.arming.dialog.editWeekday.setTimerange') }}
        </div>

        <v-row class="ma-0 ml-3 mr-2 pt-3 pb-2" align="center">
          <v-text-field
            v-model="timeblockConfig.time.start"
            type="time"
            color="primary"
            suffix=""
            rounded
            filled
            hide-details
            dense
            class="mr-2"
            style="max-width: 134px"
          ></v-text-field>
          -
          <v-text-field
            v-model="timeblockConfig.time.end"
            class="ml-2"
            type="time"
            color="red"
            suffix=""
            rounded
            filled
            hide-details
            dense
            style="max-width: 134px"
          ></v-text-field>
        </v-row>

        <v-radio-group
          v-model="timeblockConfig.timeRangeMode"
          row
          dense
          hide-details
          class="pb-2 pt-0 pl-3 pr-8"
          style="padding-top: 0px; margin-top: 0px"
        >
          <v-radio
            v-show="project.allowArming"
            hide-details
            :ripple="false"
            :value="timeModes.arming"
            style="width: 130px"
            ><template v-slot:label>
              <span
                :class="
                  timeblockConfig.timeRangeMode === timeModes.arming
                    ? 'primary--text font-weight-bold'
                    : ''
                "
                style="font-size: 15px; margin-bottom: 2px"
                >{{
                  $t('tools.project.arming.dialog.editWeekday.mode.arming')
                }}</span
              >
            </template></v-radio
          >

          <v-radio
            v-show="project.allowRecordings"
            style="width: 10%"
            hide-details
            :ripple="false"
            :value="timeModes.capture"
            ><template v-slot:label>
              <span
                :class="
                  timeblockConfig.timeRangeMode === timeModes.capture
                    ? 'primary--text font-weight-bold'
                    : ''
                "
                style="font-size: 15px; margin-bottom: 2px"
                >{{
                  $t('tools.project.arming.dialog.editWeekday.mode.capture')
                }}</span
              >
            </template></v-radio
          >

          <v-radio
            class="mt-1"
            style="width: 100%"
            hide-details
            :ripple="false"
            :value="timeModes.none"
            ><template v-slot:label>
              <span
                :class="
                  timeblockConfig.timeRangeMode === timeModes.none
                    ? 'primary--text font-weight-bold'
                    : ''
                "
                style="font-size: 15px; margin-bottom: 2px"
                >{{
                  $t('tools.project.arming.dialog.editWeekday.mode.none')
                }}</span
              >
            </template></v-radio
          >
        </v-radio-group>

        <v-divider class="mb-1 mr-3 ml-3" style="opacity: 0.5" />

        <!-- ---------------------------------------------------------------- -->
        <!-- MODE OUTSIDE THE TIME PERIOD OF DIALOG -->
        <!-- ---------------------------------------------------------------- -->
        <div class="grey--text ml-3" style="font-size: 14px">
          {{ $t('tools.project.arming.dialog.editWeekday.outsideTimerange') }}:
        </div>

        <v-radio-group
          v-model="timeblockConfig.timeRangeOutsideMode"
          row
          dense
          hide-details
          class="pb-3 pt-1 pl-3 pr-8"
          style="padding-top: 0px; margin-top: 0px"
        >
          <v-radio
            v-show="project.allowArming"
            :disabled="timeblockConfig.timeRangeMode === timeModes.arming"
            hide-details
            :ripple="false"
            :value="timeModes.arming"
            style="width: 130px"
            ><template v-slot:label>
              <span
                :class="
                  timeblockConfig.timeRangeOutsideMode === timeModes.arming
                    ? 'primary--text font-weight-bold'
                    : ''
                "
                style="font-size: 15px; margin-bottom: 2px"
                >{{
                  $t('tools.project.arming.dialog.editWeekday.mode.arming')
                }}</span
              >
            </template></v-radio
          >

          <v-radio
            v-show="project.allowRecordings"
            :disabled="timeblockConfig.timeRangeMode === timeModes.capture"
            style="width: 10%"
            hide-details
            :ripple="false"
            :value="timeModes.capture"
            ><template v-slot:label>
              <span
                :class="
                  timeblockConfig.timeRangeOutsideMode === timeModes.capture
                    ? 'primary--text font-weight-bold'
                    : ''
                "
                style="font-size: 15px; margin-bottom: 2px"
                >{{
                  $t('tools.project.arming.dialog.editWeekday.mode.capture')
                }}</span
              >
            </template></v-radio
          >

          <v-radio
            :disabled="timeblockConfig.timeRangeMode === timeModes.none"
            style="width: 100%"
            class="mt-1"
            hide-details
            :ripple="false"
            :value="timeModes.none"
            ><template v-slot:label>
              <span
                :class="
                  timeblockConfig.timeRangeOutsideMode === timeModes.none
                    ? 'primary--text font-weight-bold'
                    : ''
                "
                style="font-size: 15px; margin-bottom: 2px"
                >{{
                  $t('tools.project.arming.dialog.editWeekday.mode.none')
                }}</span
              >
            </template></v-radio
          >
        </v-radio-group>
      </div>
    </template>

    <template v-slot:actions>
      <v-row class="ma-0 mt-2" align="center">
        <v-spacer />
        <TextButton
          style="border: 1px solid #d0d0d0; border-radius: 15px"
          dense
          :text="$t('helper.buttons.close')"
          v-on:click="$emit('close')"
        />
      </v-row>
    </template>
  </DialogTemplate>
</template>

<script>
import DialogTemplate from '@components/dialog/DialogTemplate.vue';
import TextButton from '@components/button/TextButton.vue';

export default {
  name: 'EditTimeblockDialog',
  components: {
    TextButton,
    DialogTemplate,
  },

  props: {
    weekday: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      applyToAll: false,
      isRefreshing: false,
      configChanged: false,
      timeblockConfig: {
        type: 0,
        time: {
          start: '06:00',
          end: '18:00',
        },
        days: [],
        timeRangeMode: 2,
        timeRangeOutsideMode: 2,
      },
      timeModes: {
        arming: 0,
        capture: 1,
        none: 2,
      },
      scheduleModes: {
        noSetting: 0,
        fullArming: 1,
        fullCapture: 2,
        setTimeRange: 3
      }
    };
  },

  watch: {
    show() {
      this.timeblockConfig.days = [];
      if (this.show === true) this.mapTimeblockData();
    },

    'timeblockConfig.timeRangeMode': {
      handler() {
        if (
          this.timeblockConfig.timeRangeMode === this.timeblockConfig.timeRangeOutsideMode
        ) {
          this.timeblockConfig.timeRangeOutsideMode =
            this.timeblockConfig.timeRangeMode !== this.timeModes.none
              ? this.timeModes.none
              : this.project.allowArming
              ? this.timeblockConfig.timeRangeMode === this.timeModes.arming
                ? this.timeModes.capture
                : this.timeModes.arming
              : this.timeblockConfig.timeRangeMode === this.timeModes.capture
              ? this.timeModes.arming
              : this.timeModes.capture;
        }
      },
      immediate: true,
    },

    applyToAll() {
      if (this.applyToAll) this.timeblockConfig.days = [1, 2, 3, 4, 5, 6, 7];
    },

    timeblockConfig: {
      handler() {
        if (this.isRefreshing === false) this.saveTimeblockData();
      },
      deep: true,
    },

    weekday: {
      handler() {
        if (!this.show) {
          setTimeout(() => {
            this.timeblockConfig = {
              type: 0,
              time: {start: '06:00', end: '18:00'},
              days: [],
            };
            this.isRefreshing = false;
            this.configChanged = false;
          }, 100);
        } else {
          this.mapTimeblockData();
        }
      },
      immediate: true,
    },
  },

  computed: {
    project() {
      return this.$store.getters.project.general;
    },
  },

  methods: {
    clickedCheckboxEvent() {
      if (this.applyToAll === false) {
        this.timeblockConfig.days = [];
      }
    },

    getTimeModes(timeBlockType) {
      switch (timeBlockType - 1) {
        case 0:
          return this.timeModes.arming
        case 1:
          return this.timeModes.capture
        default:
          return;
      }
    },

    mapTimeblockData() {
      this.isRefreshing = true;
      const TIMEBLOCK_ARMING = 1;
      const TIMEBLOCK_CAPTURE = 2;

      this.timeblockConfig.days = [];

      if (this.data.length === 0) {
        // --------------------------------------------------------------------
        // Mode: NO Setting
        // --------------------------------------------------------------------
        this.timeblockConfig.type = this.scheduleModes.noSetting; 
      } else if (
        this.data[0].type === TIMEBLOCK_CAPTURE &&
        this.data[0].startTime === 0 &&
        this.data[0].endTime === 1440
      ) {
        // --------------------------------------------------------------------
        // Mode: Full Capture (or Documentation) (24h)
        // --------------------------------------------------------------------
        this.timeblockConfig.type = this.scheduleModes.fullCapture; 
      } else if (
        this.data[0].type === TIMEBLOCK_ARMING &&
        this.data[0].startTime === 0 &&
        this.data[0].endTime === 1440
      ) {
        // --------------------------------------------------------------------
        // Mode: Full Arming (or Safeguarding) (24h)
        // --------------------------------------------------------------------
        this.timeblockConfig.type = this.scheduleModes.fullArming;
      } else {
        // --------------------------------------------------------------------
        // Mode: Set time period
        // --------------------------------------------------------------------
        this.timeblockConfig.type = this.scheduleModes.setTimeRange;

        if (this.data.length === 2 || this.data.length === 1) {

          this.timeblockConfig.time.start = this.parseFloatTime(
            this.data[0].startTime,
          );
          this.timeblockConfig.time.end = this.parseFloatTime(
            this.data[0].endTime,
          );

          // -------------------------------------------------------------------
          // When current day has 2 timeblocks (ex: 00:00-06:00 ... 18:00-00:00)
          // -------------------------------------------------------------------
          if (this.data.length === 2) {
            if (this.data[0].type === this.data[1].type) {
              this.timeblockConfig.timeRangeOutsideMode = this.getTimeModes(this.data[0].type);
              this.timeblockConfig.timeRangeMode = this.timeModes.none;
              this.timeblockConfig.time.start = this.parseFloatTime(
                this.data[0].endTime,
              );
              this.timeblockConfig.time.end = this.parseFloatTime(
                this.data[1].startTime,
              );
            // // this condition below is useless
            // } else {
            //   this.timeblockConfig.timeRangeOutsideMode = this.getTimeModes(this.data[0].type);
            //   this.timeblockConfig.timeRangeMode = this.getTimeModes(this.data[1].type);
            }
          }

          // ------------------------------------------------------------------
          // When current day has 1 timeblock (ex: 06:00 - 18:00)
          // ------------------------------------------------------------------
          if (this.data.length === 1) {
            this.timeblockConfig.timeRangeOutsideMode = this.timeModes.none;
            this.timeblockConfig.timeRangeMode = this.getTimeModes(this.data[0].type);
          }

        }

        // -------------------------------------------------------------------------------
        // When current day has 3 timeblocks (ex: 00:00-06:00...06:00-18:00...18:00-00:00)
        // -------------------------------------------------------------------------------
        if (this.data.length === 3) {
          this.timeblockConfig.time.start = this.parseFloatTime(
            this.data[0].endTime,
          );
          this.timeblockConfig.time.end = this.parseFloatTime(
            this.data[2].startTime,
          );
          this.timeblockConfig.timeRangeMode = this.getTimeModes(this.data[1].type);
          this.timeblockConfig.timeRangeOutsideMode = this.getTimeModes(this.data[0].type);

          //  if (this.data[0].type === TIMEBLOCK_CAPTURE && this.data[0].startTime === 0) this.timeblockConfig.time.start = this.parseFloatTime(this.data[0].startTime);
          // else this.timeblockConfig.time.start = this.parseFloatTime(this.data[0].endTime)

          // if (this.data[0].type === TIMEBLOCK_CAPTURE && this.data[0].startTime === 0) this.timeblockConfig.time.end = this.parseFloatTime(this.data[0].endTime);
          // else if (this.data.length === 2 && this.data[1].type === TIMEBLOCK_CAPTURE) this.timeblockConfig.time.end = '00:00';
          // else this.timeblockConfig.time.end = this.parseFloatTime(this.data[(this.data.length - 1)].startTime)
        }
      }
      // if (this.data != null && this.data.length > 0) {
      //   if (this.data.length === 1) {
      //     if (this.data[0].type != TIMEBLOCK_ARMING) this.timeblockConfig.type = 0;
      //
      //     else if (this.data[0].type === TIMEBLOCK_ARMING && (this.data[0].startTime === 0 && this.data[0].endTime === 1440)) this.timeblockConfig.type = 1;
      //     else  {
      //       this.timeblockConfig.type = 2;
      //       this.timeblockConfig.time.start = this.parseFloatTime(this.data[0].endTime)
      //       this.timeblockConfig.time.end = '00:00';
      //     }
      //
      //   } else if (this.data.length === 2 || this.data.length === 3) {
      //     this.timeblockConfig.type = 2;
      //     this.timeblockConfig.time.start = this.parseFloatTime(this.data[0].endTime)
      //     if (this.data.length === 2 && this.data[1].type != TIMEBLOCK_ARMING) this.timeblockConfig.time.end = '00:00';
      //     else this.timeblockConfig.time.end = this.parseFloatTime(this.data[(this.data.length - 1)].startTime)
      //   }
      // } else {
      //   this.timeblockConfig = {type: 0, time: {start: '06:00', end: '18:00'}, days: []};
      // }

      //this.timeblockConfig = {type: 0, time: {start: '06:00', end: '18:00'}, days: []};

      this.$nextTick(() => {
        this.isRefreshing = false;
      });
    },

    saveTimeblockData() {
      if (this.isRefreshing === true) return;

      if (!this.timeblockConfig.days.includes(this.weekday))
        this.timeblockConfig.days.push(this.weekday);
      var endTime = this.parseStringTime(this.timeblockConfig.time.end);
      endTime = endTime === 0 ? 1440 : endTime;

      const startTime = this.parseStringTime(this.timeblockConfig.time.start);

      const dataToEmit = {
        days: this.timeblockConfig.days,
        type: this.timeblockConfig.type,
        time: {
          start: startTime > endTime ? endTime : startTime,
          end: endTime < startTime ? 1440 : endTime,
        },
        timeRange: {
          in: this.timeblockConfig.timeRangeMode,
          out: this.timeblockConfig.timeRangeOutsideMode,
        },
      }

      this.$emit('updated', dataToEmit);
    },

    parseFloatTime(time) {
      if (time === 1440) time = 0;
      return `${`0${parseInt(time / 60)}`.slice(-2)}:${`0${parseInt(
        60 * (time / 60 - parseInt(time / 60)),
      )}`.slice(-2)}`;
    },
    parseStringTime(time) {
      return +time.split(':')[0] * 60 + +time.split(':')[1];
    },

    addSelectedDayEvent(dayIdentifier) {
      if (!this.timeblockConfig.days.includes(dayIdentifier)) {
        this.timeblockConfig.days.push(dayIdentifier);
      } else {
        this.timeblockConfig.days.splice(
          this.timeblockConfig.days.indexOf(dayIdentifier),
          1,
        );
      }
    },
  },
};
</script>

<style scoped>
.day-selector {
  border-radius: 50%;
  margin-right: 14px;
}
</style>
